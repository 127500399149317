// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/img/id001.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/img/id002.jpg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../../assets/img/id003.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".my-upload[data-v-294f7003]{position:relative;margin-bottom:1.5625rem}.custom-upload[data-v-294f7003]{text-align:center;height:100%;position:relative}.custom-upload .el-upload[data-v-294f7003]{width:100%;height:100%}.custom-upload div[data-v-294f7003]{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);display:flex;flex-direction:column}.custom-upload div span[data-v-294f7003]{line-height:1.5em}[data-v-294f7003] .avatar-uploader-icon{width:5.208333rem;height:5.208333rem;line-height:5.208333rem;text-align:center;background:#c93;opacity:.5;color:#fff;font-size:1.875rem;border-radius:50%}.bg1[data-v-294f7003]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%}.bg2[data-v-294f7003]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:100% 100%}.bg3[data-v-294f7003]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;background-size:100% 100%}.detail[data-v-294f7003]{width:100%;height:100%;text-align:center;border:.052083rem solid #f5f5f5;display:flex;align-items:center;justify-content:center}.detail img[data-v-294f7003]{max-width:100%;height:100%;max-height:100%;display:inline-block}.detail .remove[data-v-294f7003]{display:none}.name[data-v-294f7003]{text-align:center}.name span[data-v-294f7003]{cursor:pointer;color:#0294ff}", ""]);
// Exports
module.exports = exports;
